"use client";

import { RootState } from "@/reduxState/store";
import { UIState } from "@/reduxState/uiSlice";
import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import React, { HTMLAttributes, forwardRef } from "react";
import { useSelector } from "react-redux";

//TODO make duration a globlal variable
// Flex-col and justify-between are used to push the footer to the bottom of the page
const classNameVariants = cva("transition-all duration-200", {
  variants: {
    variant: {
      default:
        "xl:max-w-10/12 relative max-w-[2800px] overflow-x-auto overflow-y-scroll bg-background px-1 md:px-4 lg:px-16 xl:px-24 2xl:px-40",
      blank: "",
      padded: "xl:max-w-10/12 relative max-w-[2800px] bg-background px-1 md:px-4 lg:px-8 xl:px-16 2xl:px-40",
      noScroll: "xl:max-w-10/12 fixed max-w-[2800px] bg-background px-1 md:px-4 lg:px-16 xl:px-24 2xl:px-40",
    },
    width: {
      default: "left-sideMenu-width-half w-full-minus-sideMenu",
      sideMenuExpanded: "left-sideMenu-widthExpanded-half w-full-minus-sideMenuExpanded max-w-full-minus-sideMenuExpanded",
      full: "w-full justify-center",
      blank: "w-full",
    },
    height: {
      default: "min-h-screen-minus-navbar",
      nav: "top-navbar-height",
      navWithBanner: "h-full-minus-navbar-banner top-navbar-banner-height",
      blank: "h-full",
    },
  },
  defaultVariants: {
    variant: "default",
    width: "default",
    height: "default",
  },
});

export interface PageContentContainerProps extends HTMLAttributes<HTMLInputElement>, VariantProps<typeof classNameVariants> {
  children?: React.ReactNode; // If you want to explicitly type the children prop
  blank?: boolean;
  className?: string;
  useUIState?: boolean;
}

export const PageContentContainer = forwardRef<HTMLDivElement, PageContentContainerProps>(
  ({ children, blank, className, variant = undefined, useUIState = false, ...props }, ref) => {
    let width = props.width;
    let height = props.height;

    if (variant === "blank" || blank === true) {
      width = "blank";
      height = "blank";
    }

    const UIState = useSelector((state: RootState) => state.ui) as UIState; // Access user state

    let UIStateClassName = "";

    if (useUIState) {
      if (UIState?.sideMenuOpen) {
        width = "sideMenuExpanded";
      }
      if (UIState.bannerOpen) {
        height = "navWithBanner";
      } else {
        height = "nav";
      }
    }

    console.log("UIState", UIState);
    console.log("height", height);
    console.log("width", width);

    return (
      <div ref={ref} className={cn(classNameVariants({ variant, width, height }), UIStateClassName, className)} {...props}>
        {children}
      </div>
    );
  }
);

PageContentContainer.displayName = "PageContentContainer";
