import React, { forwardRef, useState, useEffect } from "react";
import { cn } from "@/lib/utils";
import { type VariantProps, cva } from "class-variance-authority";
import { ParapgraphWithDifferenceBrackets } from "./ParagraphWithDifferentBrackets";

const headingVariants = cva("text-darkBlue", {
  variants: {
    h: {
      1: "text-3xl text-darkBlue pt-2 md:pt-[12px] lg:pt-6 xl:pt-12 pb-6 font-medium",
      2: "text-2xl py-4",
      3: "text-xl py-6",
      4: "text-lg font-medium",
      5: "text-base font-normal text-primary-dark",
    },
  },
  defaultVariants: {
    h: 4,
  },
});

const subtextVariants = cva("text-primary-dark", {
  variants: {
    subtextSize: {
      1: "mt-4 text-lg",
      2: "mt-4 text-base",
      3: "text-base",
      4: "text-sm font",
      5: "text-sm text-primary-dark font-light",
    },
  },
  defaultVariants: {
    subtextSize: 5,
  },
});

export interface HeadingProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof headingVariants>, VariantProps<typeof subtextVariants> {
  title?: string;
  subtitle?: string;
  className?: string;
  subtitleClassName?: string;
  specialBracketedClassName?: boolean;
  bracketsRegex?: RegExp;
  prependString?: string;
  prependAfterOpeningBracket?: boolean;
}

export const Heading = forwardRef<HTMLDivElement, HeadingProps>(
  (
    {
      title,
      subtitle,
      h,
      subtextSize,
      className,
      subtitleClassName,
      specialBracketedClassName,
      prependString,
      prependAfterOpeningBracket = false,
      bracketsRegex = /\([^)]+\)/g,
      ...props
    },
    ref
  ) => {
    const subTextClassName = cn("", subtextVariants({ subtextSize: subtextSize ?? h }), subtitleClassName);

    return (
      <div className={className} {...props}>
        <div className={cn(headingVariants({ h }), className)}>{h === 1 ? <h1>{title ?? props.children}</h1> : <h2>{title ?? props.children}</h2>}</div>
        {subtitle && specialBracketedClassName ? (
          <ParapgraphWithDifferenceBrackets
            text={subtitle}
            prependString={prependString}
            bracketsRegex={bracketsRegex}
            prependAfterOpeningBracket={prependAfterOpeningBracket}
            className={subTextClassName}
            bracketedClassName="font-normal"
          />
        ) : (
          <p className={subTextClassName}>{subtitle}</p>
        )}
      </div>
    );
  }
);

Heading.displayName = "Heading";
