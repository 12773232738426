"use client";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious, type CarouselApi } from "./carousel";
import Autoplay from "embla-carousel-autoplay";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { forwardRef } from "react";
import { Button } from "../ui/Button";
import { cn } from "@/lib/utils";

interface CarouselAutoplayProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  contentName?: string;
  items: React.JSX.Element[];
}

export const CarouselAutoplay = forwardRef<HTMLDivElement, CarouselAutoplayProps>(({ className, contentName, items, ...props }, ref) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  const plugin = useRef(Autoplay({ delay: 9000, stopOnInteraction: false }));
  return (
    <div ref={ref} className={cn("flex flex-col items-center p-4", className)}>
      <div className="w-full rounded-lg bg-primary-light outline outline-border outline-1">
        <Carousel setApi={setApi} plugins={[plugin.current]} className="w-full " onMouseEnter={plugin.current.stop} onMouseLeave={plugin.current.reset}>
          <CarouselContent className="items-center">
            {items.map((item, index) => (
              <CarouselItem key={index} className="h-fit w-full">
                {item}
              </CarouselItem>
            ))}
          </CarouselContent>

          <CarouselPrevious className="h-3/5" />
          <CarouselNext className="h-3/5"/>
        </Carousel>
      </div>
      <div className="py-2 text-center text-sm text-muted-foreground">
        {contentName ?? "Slide"} {current} of {count}
      </div>
    </div>
  );
});

CarouselAutoplay.displayName = "CarouselAutoplay";
