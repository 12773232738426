import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import { Button } from "./ui/Button";
import { HREFLink } from "./ui/HREFLink";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { fontSize } from "@mui/system";
import { ThemeToggleSwitch } from "./ui/ThemeToggleSwitch";
import { ThemeToggleButton } from "./ui/ThemeToggleButton";

interface FooterProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

export const Footer = forwardRef<HTMLDivElement, FooterProps>(({ className, ...props }, ref) => {
  return (
    <div className="flex justify-between bg-primary-light p-2 align-baseline" style={{}}>
      <div>
        <Button variant={"ghost"} size="md" asChild>
          <HREFLink newTab={true} url="mailto:erik@koning.ca" className="">
            Email me
          </HREFLink>
        </Button>
      </div>
      <div className="pl-20">
        <Button
          variant="ghost"
          size="md"
          innerClassName="flex gap-x-2"
          onClick={() => window.open("https://drive.google.com/uc?export=download&id=1_k2SyZTfqQApC0miP8mVafLhgnlXHKVc", "_blank")}
        >
          <DownloadRoundedIcon style={{ fontSize: 18 }} />
          <div>Download Resume</div>
        </Button>
      </div>
      <div className="flex gap-x-1">
        <Button variant="ghost" size="md" className="" onClick={() => window.open("https://www.linkedin.com/in/erikkoningcanada/", "_blank")}>
          LinkedIn
        </Button>
        <Button variant="ghost" size="md" className="" onClick={() => window.open("https://www.github.com/Erik-koning", "_blank")}>
          GitHub
        </Button>
        <Button variant="ghost" size="md" className="" onClick={() => window.open("https://www.x.com/ErikKoning8", "_blank")}>
          X
        </Button>
        <ThemeToggleButton className="flex items-center px-2 hover:bg-accent" />
      </div>
    </div>
  );
});

Footer.displayName = "Footer";
