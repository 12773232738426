import { useState, useEffect, useRef, RefObject } from "react";

export const useParentWidth = (ref?: RefObject<HTMLElement>): [RefObject<HTMLDivElement>, number | undefined] => {
  const parentRef = useRef<HTMLDivElement | null>(null); // To reference the parent element
  const [parentWidth, setParentWidth] = useState<number | undefined>(undefined); // To store the parent width

  useEffect(() => {
    // Function to update the parent width
    const updateWidth = () => {
      // If a ref is passed, use it
      if (ref && ref.current) {
        setParentWidth(ref.current.offsetWidth);
        return;
      }
      if (parentRef && parentRef.current) {
        setParentWidth(parentRef.current.offsetWidth);
      }
    };

    // Initial width calculation
    updateWidth();

    // Event listener for window resize to recalculate width
    window.addEventListener("resize", updateWidth);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  // Return the ref and the parent width
  return [parentRef, parentWidth];
};

export default useParentWidth;
