import React, { forwardRef, useEffect, useRef, useState } from "react";
import { HoverCardSimple } from "./HoverCardSimple";
import { Button } from "./Button";

export interface HREFLinkProps {
  newTab?: boolean;
  url: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: any) => void;
}

export const HREFLink = forwardRef<HTMLLinkElement, HREFLinkProps>(({ newTab, url, className, children, onClick }, ref) => {
  return (
    <a href={url} target={newTab ? "_blank" : "_self"} rel={newTab ? "noopener noreferrer" : ""} onClick={onClick}>
        <HoverCardSimple className={className}>{children ?? "Link"}</HoverCardSimple>
    </a>
  );
});
HREFLink.displayName = "HREFLink";
