// timelineData.ts

export interface TimelineItem {
  date: string;
  title: string;
  description: string;
  skills?: string[];
  width?: number;
  height?: number;  
  className?: string;
  variant?: string;
}

export const timelineData: TimelineItem[] = [
  {
    date: "September 2022 - Present",
    title: "Lead Full Stack Developer at Refdocs Inc.",
    description:
      "Architected and developed a modern, type-safe tech stack using the T3 stack centered around TypeScript to meet customer goals. Deployed a responsive software solution on AWS with automatic integration and deployment. Emphasized testing, security compliance (HIPAA/PIPEDA), and performance optimization using server-side rendering with React.js. Leveraged tools like Prisma ORM, MongoDB, Auth.js, Tailwind CSS, and OpenAI APIs to enhance development efficiency and user experience.",
    skills: [
      "TypeScript",
      "T3 stack",
      "AWS",
      "Linux",
      "Automatic deployment",
      "Testing",
      "Security compliance",
      "HIPAA",
      "PIPEDA",
      "Server-side rendering",
      "React.js",
      "Prisma ORM",
      "MongoDB",
      "Auth.js",
      "Tailwind CSS",
      "OpenAI APIs",
      "Algorithms",
      "Performance optimization",
      "Secure client-server communication",
      "API rate limiting",
      "User-usage tier plans",
      "JSON-driven UI templates",
    ],
  },
  {
    date: "January 2021 - July 2022",
    title: "End-to-End Digital Solution Consultant (Self-Employed)",
    description:
      "Provided consulting services to various companies, developing software-defined systems offering innovative solutions to complex, high-reliability problems. Worked across the entire stack from PCB circuit design to networking protocols, leading-edge web development frameworks, modern security techniques, and elegant DevOps with CI/CD. Transformed ideas into code, troubleshooting complex issues, and optimizing performance to exceed client expectations.",
    skills: [
      "PCB circuit design",
      "Networking protocols",
      "Web development frameworks",
      "Security techniques",
      "DevOps",
      "CI/CD",
      "Troubleshooting",
      "Performance optimization",
      "Continuous learning",
      "TypeScript",
      "JavaScript",
      "React.js",
      "Node.js",
      "cybergrow.ca",
      "opbox.ca",
      "westlakeebikes.ca",
    ],
  },
  {
    date: "May 2020 - September 2020",
    title: "Software Developer at Promovere",
    description:
      "Implemented an internal job marketplace powered by machine learning to match jobs with employees, featuring career pathing and mentorship matching. Worked in a small team using the MERN stack and Python for cloud-based ML. Developed dynamic web pages with HTML and CSS rendered by user data, and built an authentication layer from scratch.",
    skills: [
      "MERN stack",
      "MongoDB",
      "Express.js",
      "React.js",
      "Node.js",
      "Python",
      "Machine Learning",
      "Cloud computing",
      "HTML",
      "CSS",
      "Dynamic web pages",
      "Authentication systems",
      "Team collaboration",
    ],
  },
  {
    date: "April 2019 - April 2020",
    title: "Co-Founder at Doppl",
    description:
      "Co-founded Doppl during Queen's University startup incubator to help businesses manage distributed assets efficiently using compact sensors providing actionable data. Led embedded firmware development and PCB design for smart IoT sensors using C. Researched and integrated leading-edge sensors into a production-ready system, focusing on memory and power optimization, algorithms, and low-level protocols. Exited at an equity event leading to rebranding as 'Haztracker'. We were this cohorts fasting growing startup in the first 18 months.",
    skills: [
      "Entrepreneurship",
      "Embedded firmware development",
      "PCB design",
      "IoT sensors",
      "C programming",
      "Sensor integration",
      "Memory optimization",
      "Power optimization",
      "Algorithms",
      "Low-level protocols",
      "Leadership",
      "Startup incubation",
      "Data visualization",
    ],
  },
  {
    date: "February 2020",
    title: "Third Place in Queen's Entrepreneurship Competition",
    description: "Finalist and third place in Queen's Entrepreneurship Competition, this placement earned us a 5k cash prize and mentorship.",
    skills: ["Entrepreneurship", "Business planning", "Presentation skills"],
  },
  {
    date: "May 2019 - September 2019",
    title: "Incubator Participant at Dunin-Deshpande Queen's Innovation Centre",
    description:
      "Participated in the S19 batch of the DDQIC incubator program with Doppl. Received mentorship, office space, resources, and support from the DDQIC network.",
    skills: ["Startup incubation", "Mentorship", "Entrepreneurship", "Networking", "Resource utilization"],
  },
  {
    date: "January 2015 - December 2019",
    title: "Bachelor of Applied Science in Computer Engineering at Queen's University",
    description:
      "Completed a Bachelor's degree in Computer Engineering, gaining knowledge in software development, hardware design, algorithms, and data structures.",
    skills: ["Computer Engineering", "Programming", "Software development", "Hardware design", "Algorithms", "Data structures"],
  },
  {
    date: "May 2018 - August 2018",
    title: "Student Software Engineer at BlackBerry",
    description:
      "Ported security features from legacy devices to newer IoT devices in a dynamic team. Worked on AOSP development, IoT security, and patch integration. Developed BASH scripts to enhance testing procedures, worked with Java RE security features, and used CMake to build large software projects.",
    skills: [
      "AOSP development",
      "IoT security",
      "Patch integration",
      "BASH scripting",
      "Testing procedures",
      "Java RE security",
      "CMake",
      "Large software projects",
      "Team collaboration",
    ],
  },
  {
    date: "September 2016 - March 2018",
    title: "System Engineer at Queen's Mostly Autonomous Sailboat Team",
    description: "Developed systems and sensors enabling autonomous sailing capabilities to navigate and complete objectives in national competitions.",
    skills: ["Autonomous systems", "Sensors", "System development", "Robotics", "Team collaboration", "Navigation algorithms", "Competitions"],
  },
  {
    date: "March 2019",
    title: "First Place in Kingston Mayor's Innovation Challenge",
    description: "Awarded first place in the Kingston Mayor's Innovation Challenge for innovative solutions.",
    skills: ["Innovation", "Problem-solving", "Presentation skills"],
  },
  {
    date: "May 2015 - May 2018",
    title: "Kiteboarding Instructor at Airtime Kiteboarding",
    description:
      "Transformed a passion for kiteboarding into a job, teaching people to kiteboard safely since 2015. Provided instruction in an exhilarating and sustainable watersport.",
    skills: ["Instruction", "Kiteboarding", "Safety procedures", "Customer service", "Entrepreneurship", "Communication"],
  },
  {
    date: "June 2006 - September 2012",
    title: "Student to Instructor at West Lake Catamaran Sailing School",
    description: "Provided sailing instruction at West Lake Catamaran Sailing School, teaching students sailing skills and safety procedures.",
    skills: ["Sailing instruction", "Sailing", "Teaching", "Customer service", "Communication"],
  }
];
