import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import { ItemsScroll } from "./itemsScroll";
import { SmartScroll } from "./smartScroll";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { CuteWordOrb } from "../ui/CuteWordOrb";

interface TimelineCardProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  data: Record<string, any>;
  index?: number;
}

export const TimelineCard = forwardRef<HTMLDivElement, TimelineCardProps>(({ className, data, index, ...props }, ref) => {
  const [currAccordionIdx, setCurrAccordionIdx] = useState<string[]>([]);

  const handleAccordionTriggerClick = (idx: string) => {
    if(currAccordionIdx.includes(idx)) {
      setCurrAccordionIdx([]);
    }
    else {
      setCurrAccordionIdx([idx]);
    }
  }
  
  return (
    <div
      ref={ref}
      {...props}
      className="flex flex-col items-start rounded-md bg-primary-light p-1.5 text-start text-primary-dark outline outline-border hover:z-50"
    >
      {index !== undefined && (
        <div className="flex items-center gap-x-2">
          <div className="flex h-fit px-2 py-1 w-fit items-center justify-center rounded-md bg-skyBlue text-white dark:bg-accent">{index + 1}</div>
          <h3 className="text-xl font-semibold text-center">{data.title}</h3>
        </div>
      )}
      <div className="mt-2 text-center">
        <time className="mb-2 block ">{data.date}</time>
      </div>
      <p className="">{data.description}</p>
      <div></div>
      <Accordion type="multiple" value={currAccordionIdx} className="w-full px-2">
        <AccordionItem
          value="skills"
          onClick={() => {
            handleAccordionTriggerClick("skills");
          }}
        >
          <AccordionTrigger className="mt-2">
            <h4 className="text-lg font-semibold">Skills Honed</h4>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-wrap gap-x-2 gap-y-0.5">
              {data.skills.map((skill: string, index: number) => (
                <CuteWordOrb maxWidthPX={200} key={index} className="bg-secondary-light p-0.5 w-fit" text={skill} />
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
});

TimelineCard.displayName = "TimelineCard";
