import React, { useEffect, useState, useRef, forwardRef, useLayoutEffect, use } from "react";
import { TimelineItem, timelineData } from "./timelineConfig";
import { TimelineCard } from "./timelineCard";
import useParentWidth from "@/hooks/useParentWidth";
import useWindowSize from "@/hooks/useWindowSize";
import { debug } from "console";
import useMousePosition from "@/hooks/useMousePosition";
import { set } from "lodash";
import { cn } from "@/lib/utils";

interface nonTouchTimelineProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultWidth?: number;
  defaultHeight?: number;
  className?: string;
  onHandleLoadedAll?: (loadedAll: boolean) => void;
  handleTimelineLoadedHeight?: (height: number) => void;
}

const NonTouchTimeline = forwardRef<HTMLDivElement, nonTouchTimelineProps>(
  ({ onHandleLoadedAll, handleTimelineLoadedHeight, defaultHeight = 150, defaultWidth = 380, className, ...props }, ref) => {
    const [visibleItemCount, setVisibleItemCount] = useState(0);
    const avoidDoubleInvoke = useRef(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const itemRefs = useRef<HTMLDivElement[]>([]);
    //const [parentPaddingLeft, setParentPaddingLeft] = useState<number | undefined>(undefined);
    const [randomizedItemCoordinates, setRandomizedItemCoordinates] = useState<{ x: number; y: number }[]>([]);
    const [itemCoordinates, setItemCoordinates] = useState<{ x: number; y: number }[]>([]);
    const [crowdedCoordinates, setCrowdedCoordinates] = useState<{ x: number; y: number }[]>([]);
    const [spacedItemCoordinates, setSpacedItemCoordinates] = useState<{ x: number; y: number }[]>([]);
    const [organizedAlternatingCoordinates, setOrganizedAlternatingCoordinates] = useState<{ x: number; y: number }[]>([]);
    const [timelineIsFocused, setTimelineIsFocused] = useState(false);
    const [totalHeightOrganized, setTotalHeightOrganized] = useState<number | undefined>(undefined);
    const [totalHeightCrowded, setTotalHeightCrowded] = useState<number | undefined>(undefined);

    const [parentRef, parentWidth] = useParentWidth(containerRef); // Use the hook

    const { x: mouseX, y: mouseY } = useMousePosition(); // Use the hook

    useEffect(() => {
      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const containerTop = containerRect.top;

      console.log("Mouse Y:", mouseY);
      console.log("Container offsetTop:", containerTop);

      if (organizedAlternatingCoordinates.length !== timelineData.length) return;

      if (mouseY > containerTop) {
        setItemCoordinates(organizedAlternatingCoordinates);
        setTimelineIsFocused(true);
      } else {
        setItemCoordinates(crowdedCoordinates);
        setTimelineIsFocused(false);
      }
    }, [mouseX, mouseY]);

    useEffect(() => {
      //everytime the timeline is focused, we want to re-run the crowded coordinates calculation
      handleTimelineLoadedHeight && handleTimelineLoadedHeight(timelineIsFocused ? totalHeightOrganized ?? 0 : totalHeightCrowded ?? 0);
    }, [timelineIsFocused]);

    //useWindowSize(); // Use the hook

    function getAbsoluteLeft(el: HTMLElement | null): number {
      let left = 0;
      while (el) {
        left += el.offsetLeft;
        const parent = el.offsetParent;
        if (!parent) break;
        el = parent as HTMLElement;
      }
      return left;
    }

    useEffect(() => {
      if (!randomizedItemCoordinates || !parentWidth) return;

      const itemCoordinates: { x: number; y: number }[] = [];
      //debugger;
      randomizedItemCoordinates.forEach((item, idx) => {
        let x = (item.x / 100) * parentWidth;
        let y = item.y;

        let itemWidth = timelineData[idx].width ?? defaultWidth;
        let itemHeight = timelineData[idx].height ?? defaultHeight;
        let diagonal = Math.sqrt(itemWidth ** 2 + itemHeight ** 2);

        let itemRight = x + itemWidth;
        let itemBottom = y + itemHeight;

        // Adjust the x position if the item is overflowing
        if (itemRight > parentWidth) {
          const diff = itemRight - parentWidth;
          x -= diff;
        }

        itemCoordinates.push({ x, y });
      });
      setCrowdedCoordinates(itemCoordinates);
      setItemCoordinates(itemCoordinates);
    }, [parentWidth, randomizedItemCoordinates]);

    //nicely organized timeline
    useLayoutEffect(() => {
      if (!timelineData || !parentWidth || itemRefs.current.length === 0 || visibleItemCount < timelineData.length) return;

      // Ensure all refs are available
      const allRefsAvailable = itemRefs.current.every((ref) => ref);
      if (!allRefsAvailable) return;
      //debugger;

      const newlyPlacedCoordinates: { x: number; y: number }[] = [];
      let yBottoms = new Array<number>(visibleItemCount).fill(0);
      const verticalSpacing = 35;
      const horizontalOffset = (parentWidth / 2) * 0.5; // 33% of parentWidth
      const centerX = parentWidth / 2;

      let currentYLeft = 0;
      let currentYRight = 0;
      let currentY = 0;

      for (let idx = 0; idx < visibleItemCount; idx++) {
        const itemRef = itemRefs.current[idx];
        if (!itemRef) continue;

        const itemWidth = itemRef.offsetWidth;
        const itemHeight = itemRef.offsetHeight;
        const computedHeight = itemRef.clientHeight;
        const clientBounding = itemRef.getBoundingClientRect();
        console.log("computedHeight:", computedHeight);
        console.log("clientBounding:", clientBounding);
        console.log("Item width:", itemWidth);
        console.log("Item height:", itemHeight);

        let x = 0;

        if (idx % 2 === 0) {
          // Left side
          x = centerX - horizontalOffset - itemWidth / 2;
          currentY = currentYLeft;
        } else {
          // Right side
          x = centerX + horizontalOffset - itemWidth / 2;
          currentY = currentYRight;
        }

        // Position the item at currentY
        newlyPlacedCoordinates.push({ x, y: currentY });

        if (idx % 2 === 0) {
          // Left side
          currentYLeft += itemHeight + verticalSpacing;
        } else {
          // Right side
          currentYRight += itemHeight + verticalSpacing;
        }
      }
      setTotalHeightOrganized(Math.max(currentYLeft, currentYRight));

      setOrganizedAlternatingCoordinates(newlyPlacedCoordinates);
    }, [parentWidth, timelineData, visibleItemCount]);

    // Adjust coordinates to prevent overlaps
    useEffect(() => {
      if (!timelineData || !parentWidth || !crowdedCoordinates) return;

      const newlyPlacedCoordinates: { x: number; y: number }[] = [];
      const verticalSpacing = 20;
      const horizontalSpacing = 20;
      const xMargin = 10; // Adjust as needed
      const yMargin = 100; // Adjust as needed

      // Add the first item without adjustments
      if (!crowdedCoordinates[0]) return;
      let { x, y } = crowdedCoordinates[0];
      newlyPlacedCoordinates.push({ x, y });

      for (let idx = 1; idx < timelineData.length; idx++) {
        const itemWidth = timelineData[idx].width ?? defaultWidth;
        const itemHeight = timelineData[idx].height ?? defaultHeight;

        if (!crowdedCoordinates[idx]) continue;

        // Start with the initial position from crowdedCoordinates
        let { x, y } = crowdedCoordinates[idx];

        // Ensure y is not less than previous item's y
        const previousItemY = newlyPlacedCoordinates[idx - 1].y;
        if (y < previousItemY) {
          y = previousItemY;
        }

        // Adjust position to avoid overlap with any previous items
        let hasOverlap = true;
        let attemptDirection = "right"; // default direction

        // Determine if the item's center is in the left or right half
        const itemCenterX = x + itemWidth / 2;
        if (itemCenterX > parentWidth / 2) {
          attemptDirection = "left";
        }

        let shiftAmount = horizontalSpacing;
        while (hasOverlap) {
          hasOverlap = false;

          for (let j = 0; j < idx; j++) {
            const otherItem = newlyPlacedCoordinates[j];
            const otherWidth = timelineData[j].width ?? defaultWidth;
            const otherHeight = timelineData[j].height ?? defaultHeight;

            if (rectsOverlap(x, y, itemWidth, itemHeight, otherItem.x, otherItem.y, otherWidth, otherHeight, xMargin, yMargin)) {
              hasOverlap = true;

              // Attempt to shift in the chosen direction
              if (attemptDirection === "right") {
                x += shiftAmount;

                // If moving right causes overflow, try moving left
                if (x + itemWidth > parentWidth) {
                  attemptDirection = "left";
                  x -= shiftAmount;
                }
              } else {
                x -= shiftAmount;

                // If moving left causes negative x, reset position and move down
                if (x < 0) {
                  x = 0;
                  y += verticalSpacing;
                  shiftAmount += horizontalSpacing;

                  // Ensure y is not less than previous item's y
                  if (y < previousItemY) {
                    y = previousItemY;
                  }
                }
              }

              // If both directions have been tried and failed, move down
              if (x < 0 || x + itemWidth > parentWidth) {
                x = 0;
                y += verticalSpacing;

                // Ensure y is not less than previous item's y
                if (y < previousItemY) {
                  y = previousItemY;
                }
              }

              break; // Break to re-check overlaps from the beginning
            }
          }
        }

        // Ensure the item is within screen bounds
        if (x < 0) x = 0;
        if (x + itemWidth > parentWidth) x = parentWidth - itemWidth;

        newlyPlacedCoordinates.push({ x, y });
      }

      setSpacedItemCoordinates(newlyPlacedCoordinates);
    }, [crowdedCoordinates]);

    useEffect(() => {
      // Ensure the containerRef is available
      if (containerRef.current && containerRef.current.parentElement) {
        // Get the computed padding-left of the parent container
        const parentElement = containerRef.current.parentElement;
        // Get the absolute left position of the parent element
        const parentXStartAbs = getAbsoluteLeft(parentElement);
        //const parentRect = parentElement.getBoundingClientRect();
        //const parentXStart = parentRect.left; // X position of the parent
        const computedStyle = window.getComputedStyle(parentElement);
        const paddingLeft = parseFloat(computedStyle.paddingLeft);
        const parentPaddingLeftTotal = parentXStartAbs + paddingLeft;
        //setParentPaddingLeft(parentPaddingLeftTotal);
        console.log("Parent padding-left:", parentPaddingLeftTotal);
      }
    }, [parentWidth]);

    // Generate positions
    useEffect(() => {
      if (!timelineData) return;
      const newPositions: { x: number; y: number }[] = [];
      let cumulativeY = 0;

      let maxBottomY = 0;

      timelineData.forEach((_, index) => {
        let x, y;

        if (index === 0) {
          // First item at upper-left corner
          x = 0; // 10% from the left
          y = 0; // 50px from the top
          cumulativeY = y;
        } else {
          // Random positions for subsequent items
          x = 10 + Math.random() * 70; // 10% to 90%, as percentage

          const yOffset = 40 + (Math.random() * 160 - 80); // 50px ±80px
          y = yOffset + cumulativeY;
          cumulativeY += 10;
        }
        const height = itemRefs.current[index]?.offsetHeight ?? defaultHeight;
        if (y + height > maxBottomY) {
          maxBottomY = y + height;
        }

        newPositions.push({ x, y }); //x as percent y as px
      });
      console.log("Max bottom Y:", maxBottomY);
      setTotalHeightCrowded(maxBottomY);
      setRandomizedItemCoordinates(newPositions);
    }, [timelineData, timelineIsFocused]);

    useEffect(() => {
      if (avoidDoubleInvoke.current) return;
      avoidDoubleInvoke.current = true;

      // Show items one by one with a delay
      timelineData.forEach((_, index) => {
        setTimeout(() => {
          setVisibleItemCount((prevCount) => prevCount + 1);
          if (index === timelineData.length - 1) {
            setTimeout(() => {
              onHandleLoadedAll && onHandleLoadedAll(true);
            }, 150);
          }
        }, index * 200); // 400ms delay between each item
      });
    }, []);

    const handleMouseOverTimeline = (isMouseOver: boolean) => {
      if (spacedItemCoordinates.length !== crowdedCoordinates.length) return;
      setItemCoordinates(isMouseOver ? organizedAlternatingCoordinates : crowdedCoordinates);
    };

    return (
      <div
        ref={containerRef}
        className={cn("relative mt-10 w-full transition-all", className)}
        style={{
          opacity: timelineIsFocused ? 1 : 0.5,
          height: timelineIsFocused ? totalHeightOrganized ?? 2000 : totalHeightCrowded && totalHeightCrowded < 530 ? 530 : totalHeightCrowded,
        }}
        //onMouseOver={() => handleMouseOverTimeline(true)}
        //onMouseLeave={() => handleMouseOverTimeline(false)}
      >
        {/* SVG for connecting lines */}
        <svg className="absolute left-0 top-0 w-full overflow-visible" style={{ pointerEvents: "none" }}>
          {itemCoordinates &&
            itemCoordinates.slice(0, visibleItemCount).map((pos, index) => {
              if (index === visibleItemCount - 1) return null;
              if (!pos || parentWidth === undefined) return null;

              const start = itemCoordinates[index];
              const end = itemCoordinates[index + 1];

              const path = generatePath(
                start,
                end,
                parentWidth ?? 0,
                timelineData[index].width ?? defaultWidth,
                timelineData[index].width ?? defaultHeight,
                0,
                index === 3 ? 80 : 20,
                0,
                index === 1 ? -50 : -20
              );
              //console.log("Path:", path);
              return (
                <path
                  key={index}
                  d={path}
                  stroke="gray"
                  fill="none"
                  strokeWidth={2}
                  strokeLinecap="round"
                  className="animate-drawLine transition-all"
                  style={{
                    //animationDelay: `${(index + 1) * 0.4}s`,
                    animationFillMode: "forwards",
                  }}
                />
              );
            })}
        </svg>

        {/* Timeline items */}
        {timelineData.slice(0, visibleItemCount).map((item, index) => {
          const pos = itemCoordinates[index];
          if (!pos || parentWidth === undefined) return null;
          return (
            <div
              key={index}
              className="absolute z-20 animate-fadeIn overflow-visible opacity-0 transition-all hover:z-50"
              style={{
                left: `${pos.x}px`,
                top: `${pos.y}px`,
                //transform: "translate(-50%, -50%)",
                //animationDelay: `${index * 0.4}s`,
                animationFillMode: "forwards",
                width: `${item.width ?? defaultWidth}px`,
                height: `${item.height ?? defaultHeight}px`,
              }}
            >
              {/* Your timeline item content */}
              <TimelineCard ref={(el) => (itemRefs.current[index] = el as HTMLDivElement)} data={item} index={index} className="h-full w-full" />
            </div>
          );
        })}
      </div>
    );
  }
);

function generatePath(
  start: { x: number; y: number },
  end: { x: number; y: number },
  containerWidth: number,
  cardWidth: number,
  cardHeight: number,
  x1Offset = 0,
  y1Offset = 0,
  x2Offset = 0,
  y2Offset = 0
): string {
  // Calculate positions of cards
  const left1 = start.x;
  const top1 = start.y;
  const x1 = left1 + cardWidth / 2 + x1Offset;
  const y1 = top1 + cardHeight / 2 + y1Offset;

  const left2 = end.x;
  const top2 = end.y;
  const x2 = left2 + cardWidth / 2 + x2Offset;
  const y2 = top2 + cardHeight / 2 + y2Offset;

  // Rest of your path generation logic
  // ...

  const radius = Math.abs(y2 - y1) / 2 < 10 ? Math.abs(y2 - y1) / 2 : 10; // Radius for the corner

  let path = `M ${x1} ${y1} `;

  // Determine the direction
  const horizontalDirection = x2 > x1 ? 1 : -1;
  const verticalDirection = y2 > y1 ? 1 : -1;

  // Midpoint for horizontal line
  const midX = x1 + (x2 - x1) / 2;

  // Horizontal line to midX
  path += `L ${midX - horizontalDirection * radius} ${y1} `;

  // First corner
  path += `Q ${midX} ${y1}, ${midX} ${y1 + verticalDirection * radius} `;

  // Vertical line to y2
  path += `L ${midX} ${y2 - verticalDirection * radius} `;

  // Second corner
  path += `Q ${midX} ${y2}, ${midX + horizontalDirection * radius} ${y2} `;

  // Horizontal line to x2
  path += `L ${x2} ${y2} `;

  return path;
}

function rectsOverlap(x1: number, y1: number, w1: number, h1: number, x2: number, y2: number, w2: number, h2: number, xMargin: number, yMargin: number) {
  return x1 - xMargin < x2 + w2 + xMargin && x1 + w1 + xMargin > x2 - xMargin && y1 - yMargin < y2 + h2 + yMargin && y1 + h1 + yMargin > y2 - yMargin;
}

// Function to calculate horizontal overlap
function getHorizontalOverlap(x1: number, w1: number, x2: number, w2: number) {
  const left = Math.max(x1, x2);
  const right = Math.min(x1 + w1, x2 + w2);
  return Math.max(0, right - left);
}

NonTouchTimeline.displayName = "NonTouchTimeline";

export default NonTouchTimeline;
