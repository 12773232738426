"use client";

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { TimelineItem, timelineData } from "./timelineConfig";
import NonTouchTimeline from "./nonTouchTimeline";
import { cn } from "@/lib/utils";
import { TimelineCard } from "./timelineCard";

interface timelineProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  onHandleLoadedAll: (loadedAll: boolean) => void;
  handleTimelineLoaded?: (height: number) => void;
}

const Timeline = forwardRef<HTMLDivElement, timelineProps>(({ onHandleLoadedAll, handleTimelineLoaded, className, ...props }, ref) => {
  const [visibleItemCount, setVisibleItemCount] = useState(0);
  const avoidDoubleInvoke = useRef(false); // Initialize the ref
  const [isTouchDevice, setIsTouchDevice] = useState<boolean | null>(null);

  const getIsTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  };

  useEffect(() => {
    setIsTouchDevice(getIsTouchDevice() ?? false);
    // Check if the effect has already been run
    if (avoidDoubleInvoke.current) return;

    // Mark as invoked
    avoidDoubleInvoke.current = true;

    const showItemsSequentially = () => {
      timelineData.forEach((_, index) => {
        setTimeout(() => {
          setVisibleItemCount((prevCount) => prevCount + 1);
        }, index * 50); // 400ms delay between each item
      });
    };

    showItemsSequentially();
  }, []);

  if (isTouchDevice) {
    // Render content for touch devices
    onHandleLoadedAll(true);
    return (
      <div className={cn("container relative mx-auto p-4", className)}>
        {/* Vertical timeline line */}
        <div className="absolute left-1/2 hidden h-full -translate-x-1/2 transform border-l-2 border-gray-300"></div>

        {timelineData.slice(0, visibleItemCount).map((item, index, allItems) => (
          <div key={index} className="mb-8 flex flex-col items-center">
            {/* Timeline point */}
            <div
              className="hidden z-10 h-10 w-10 animate-fadeInUp items-center justify-center rounded-full shadow-lg dark:bg-accent"
              style={{ animationDelay: `${index * 0.4}s` }}
            >
              <span className="font-bold text-white">{index + 1}</span>
            </div>

            {/* Content */}
            <TimelineCard data={item} index={index} className="w-full" />

            {/* Line connecting to the next item */}
            {index !== timelineData.slice(0, visibleItemCount).length - 1 && (
              <div
                className="left-1/2 hidden w-1 -translate-x-1/2 transform animate-growLine bg-gray-300"
                style={{
                  top: "2rem",
                  height: "calc(100% - 2rem)",
                  animationDelay: `${index * 0.2}s`,
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
    );
  } else {
    // Render the non-touch device timeline
    return <NonTouchTimeline className={className} onHandleLoadedAll={onHandleLoadedAll} handleTimelineLoadedHeight={handleTimelineLoaded} />;
  }
});

Timeline.displayName = "Timeline";

export default Timeline;
